export const translateDescription = desc => {
  let lowCased = desc.toLowerCase();
  switch (lowCased) {
    case 'other':
      return 'Breakfast not included';
    case 'bedandbreakfast':
      return 'Bed and Breakfast';
    default:
      return desc;
  }
};
